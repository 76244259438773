<script setup lang="ts">
import type {PropType} from "vue";
import type {ElementorElement} from "~/integration/wordpress/types";

const props = defineProps({
  element: {
    type: Object as PropType<ElementorElement>,
    default: {},
    required: true
  }
})

const ElementWrap = !props.element?.isInner ? defineAsyncComponent(() => import('./Row.vue')) : defineAsyncComponent(() => import('./Column.vue'));
const ElementorElementComponent = defineAsyncComponent(() => import('../ElementorElement.vue'));
</script>

<template>
  <component :is="ElementWrap" :key="`container-${element.id}`">
    <template v-for="item in element.elements" :key="item.id">
      <ElementorElementComponent :element="item"/>
    </template>
  </component>
</template>